var columns = [{
  title: '项目名称',
  dataIndex: 'launchpadname',
  key: 'launchpadname',
  width: '140px',
  scopedSlots: {
    customRender: 'launchpadname'
  }
}, {
  title: '项目介绍',
  dataIndex: 'launchpaddescription',
  key: 'launchpaddescription',
  width: '260px',
  scopedSlots: {
    customRender: 'launchpaddescription'
  }
}, {
  title: 'detail',
  dataIndex: 'contractaddress',
  key: 'contractaddress',
  width: '200px',
  scopedSlots: {
    customRender: 'contractaddress'
  }
},
//   {
//     title: '社区链接',
//     dataIndex: 'communitylink',
//     key: 'communitylink',
//     width: '120px',
//     scopedSlots: {
//         customRender: 'communitylink'
//     }
// },
// {
//   title: 'Twitter链接',
//   dataIndex: 'twitterlink',
//   key: 'twitterlink',
//   width: '120px',
//   scopedSlots: {
//       customRender: 'twitterlink'
//   }
// },
{
  title: '发售起始时间(UTC)',
  dataIndex: 'starttime',
  key: 'starttime',
  width: '100px',
  scopedSlots: {
    customRender: 'starttime'
  }
}, {
  title: '发售结束时间(UTC)',
  dataIndex: 'endtime',
  key: 'endtime',
  width: '100px',
  scopedSlots: {
    customRender: 'endtime'
  }
},
//   {
//     title: 'creator',
//     dataIndex: 'creator',
//     key: 'creator',
//     width: '120px',
//     scopedSlots: {
//         customRender: 'creator'
//     }
// },
{
  title: '总供应量',
  dataIndex: 'totalsupply',
  key: 'totalsupply',
  width: '100px',
  scopedSlots: {
    customRender: 'totalsupply'
  }
}, {
  title: '发售价格',
  dataIndex: 'price',
  key: 'price',
  width: '120px',
  scopedSlots: {
    customRender: 'price'
  }
},
// {
//     title: '是否白名单',
//     dataIndex: 'whitelist',
//     key: 'whitelist',
//     width: '80px',
//     scopedSlots: {
//         customRender: 'whitelist'
//     }
// },
// {
//     title: '是否KYC',
//     dataIndex: 'kyc',
//     key: 'kyc',
//     width: '80px',
//     scopedSlots: {
//         customRender: 'kyc'
//     }
// },
{
  title: '交易方式',
  dataIndex: 'accesstype',
  key: 'accesstype',
  width: '100px',
  scopedSlots: {
    customRender: 'accesstype'
  }
},
// {
//     title: '铸造页链接',
//     dataIndex: 'minturl',
//     key: 'minturl',
//     width: '120px',
//     scopedSlots: {
//         customRender: 'minturl'
//     }
// },

{
  title: '状态',
  dataIndex: 'state',
  key: 'state',
  width: '100px',
  scopedSlots: {
    customRender: 'state'
  }
}, {
  title: '权益说明附件',
  dataIndex: 'attachmentlink',
  key: 'attachmentlink',
  width: '180px',
  scopedSlots: {
    customRender: 'attachmentlink'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  fixed: "right",
  width: '160px',
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };