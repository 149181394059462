var searchKeys = [{
  key: "date",
  label: "铸造起始时间",
  format: "YYYY-MM-DD HH:mm",
  placeholder: ["开始时间", "截止时间"],
  rules: [],
  dates: true
}, {
  key: "launchpadname",
  label: "项目名称",
  placeholder: "请输入项目名称",
  required: false,
  rules: [],
  input: true
}, {
  key: "launchpadprice",
  label: "价格",
  required: false,
  rules: [],
  inputGroup: true,
  children: [{
    key: 'minprice',
    min: 0,
    placeholder: '请输入'
  }, {
    key: 'maxprice',
    min: 0,
    placeholder: '请输入'
  }]
},
// {
//   key: "creator",
//   label: "creator",
//   placeholder: "请输入",
//   required: false,
//   rules: [],
//   input: true
// },
{
  key: "state",
  label: "状态",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true,
  initialValue: ''
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };